import React, { useState } from "react";

// libs
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";

// components
import { routes } from "../../../utils/routes";
import { Layout, LoadingSpinner } from "../../common";
import { JoinWaitListAPI } from "../../../api";

// assets
import styles from './JoinWaitList.module.scss';
import emailIcon from '../../../images/icons/email.svg';
//import walletIcon from '../../../images/icons/wallet.svg';
import userIcon from '../../../images/icons/user-single.svg';
//import questionIcon from '../../../images/icons/question.svg';
import background from "../../../images/waitlist-joined-banner.jpg";

export const EMAIL_VALIDATION_PATTERN = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email is required'),
  userType: yup.string().required('User type is required'),
  walletAddress: yup.string().min(26, 'code is too short. must be 26 - 36 chars').max(36, 'code is too long. must be 26 - 36 chars'),
});

export const JoinWaitList = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [errorEmail, setEmailError] = useState('');

function emailChange() {
  setEmailError('')
}
  return (
    <Layout title='Join the waitlist' background={background}>
      {loading && <LoadingSpinner />}
      <div className={styles.join}>
        <h1>join the <span>waitlist..</span>.</h1>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ name: '', email: '', userType: '', walletAddress: '' }}
          onSubmit={(values) => {
            setLoading(true);
            JoinWaitListAPI({
              name: values.name,
              email: values.email,
              walletAddress: values.walletAddress,
              userType: values.userType,
              artist: process.env.REACT_APP_ARTIST_ID
            })
              .then(() => {
                setLoading(false);
                navigate(routes.joinWaitListThanks);
                setError('');
              })
              .catch(({ response }) => {
                setLoading(false);
                if (response.data && (response.data.error === 'Error: Email already exists' || response.data.error === 'Email already exists' || response.data.error === 'User Already Exists' || response.data.error ===  "Already In Waitlist" || response.data.error === 'Already registered intrest')) {
                  setEmailError('Looks like that email is already in use.')
                } else {
                  setError('Something went wrong. Please try again.')
                }
              });
          }}>
          {({ errors, touched }) => (
            <Form className={styles.form}>
              <label className={errors.name && touched.name ? 'field-error' : ''}>
                <img className='icon' src={userIcon} alt='' />
                <Field name='name' type="text" placeholder="Name" />
                {errors.name && touched.name ? <p className='error'>{errors.name}</p> : null}
              </label>
              <label className={errors.email && touched.email ? 'field-error' : ''}>
                <img className='icon' src={emailIcon} alt='' />
                <Field name='email' type="email" placeholder="Your Email" onKeyUp={emailChange} />
                {errors.email && touched.email ? <p className='error'>{errors.email}</p> : null}
                {errorEmail ? <p className='error'>{errorEmail}</p> : null}
              </label>
              <div className={styles.am}>
                <p>
                  <img className='icon' src={userIcon} alt='' />
                  I'm a
                </p>
                <label className='radio'>
                  <Field hidden type="radio" name="userType" value="fan" />
                  <span className='circle' />
                  <span className='title'>Fan or Collector</span>
                </label>
                <label className='radio'>
                  <Field hidden type="radio" name="userType" value="artist" />
                  <span className='circle' />
                  <span className='title'>Artist or Label</span>
                </label>
                {errors.userType && touched.userType ? <p className='error'>{errors.userType}</p> : null}
              </div>
              {/*<label className={styles.wallet}>
                <img className='icon' src={walletIcon} alt='' />
                <Field name='walletAddress' type="text" placeholder="Public Wallet Address (Optional)" />
                <div className={styles.question}>
                  <img src={questionIcon} alt='' />
                  <div className={styles.tooltip}>
                    If you have a Crypto wallet, feel free to add the public address here to be whitelisted.
                  </div>
                </div>
                {errors.walletAddress && touched.walletAddress ? <p className='error'>{errors.walletAddress}</p> : null}
              </label>
          */}
              <p className={styles.notice}>
                I agree to receiving information on the forthcoming releases by email and other promotional material.
              </p>
              {error && <p className='error-server'>{error}</p>}
              <div className={styles.button}>
                <button
                  className='btn'
                  type='submit'>
                  join <span>now</span>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
