import React, { useState } from 'react';

// libs
import { useNavigate } from "react-router-dom";

// components
import { VideoModal } from "../../modals";
import { Benefits, Countdown, Layout } from "../../common";
import { routes } from "../../../utils/routes";

// assets
import styles from './Kayoralpha.module.scss';
import playBtn from "../../../images/icons/play.svg";
import background from "../../../images/AyseDeniz-background.jpg";
import user from "../../../images/icons/user.svg";
import mp3 from "../../../images/icons/mp3.svg";
import money from "../../../images/icons/money.svg";
import ticket from "../../../images/icons/ticket.svg";

export const Kayoralpha = () => {
  const navigate = useNavigate();

  const benefits = [
  ]
  
  if (process.env.REACT_APP_PERK_TICKET) {
    benefits.push({ title: process.env.REACT_APP_PERK_TICKET, icon: ticket })
  }
  
  if (process.env.REACT_APP_PERK_NFT_IMAGE) {
    benefits.push({ title: 'A unique numbered exclusive image just for you', icon: user })
  }
  
  if (process.env.REACT_APP_PERK_DOWNLOAD) {
    benefits.push({ title: 'A MP3 download of the song to keep', icon: mp3 })
  }
  
  if (process.env.REACT_APP_PERK_ROYALTY) {
    benefits.push({ title: 'A share of the streaming revenues from ‘' + process.env.REACT_APP_SONG_NAME +'’ forever!', icon: money })
  }
  const [showVideo, setShowVideo] = useState(false);

  const artist_name = process.env.REACT_APP_ARTIST_NAME.toUpperCase()
  const song_name = process.env.REACT_APP_SONG_NAME

  const title = process.env.REACT_APP_SONG_NAME + ' - ' + process.env.REACT_APP_ARTIST_NAME + ' on SongBits'
  const video_url = process.env.REACT_APP_VIDEO_URL

  
  const str_date = process.env.REACT_APP_RELEASE_AT 

  const year = parseInt(str_date.substring(0, 4));
  const month = parseInt(str_date.substring(5, 7)) - 1;
  const day = parseInt(str_date.substring(8, 10));
  
  const hour = parseInt(str_date.substring(11, 13));
  const minute = parseInt(str_date.substring(14, 16));
  const second = parseInt(str_date.substring(17, 19));

  const release_date = new Date(Date.UTC(year, month, day, hour, minute, second))
  const release_date_epoch = release_date.getTime()

  const perks = process.env.REACT_APP_PERKS

  return (
    <Layout title={title} artistName={process.env.REACT_APP_ARTIST_NAME} songName={process.env.REACT_APP_SONG_NAME} background={background} noLogo>
      {showVideo && <VideoModal setShowVideo={setShowVideo} src={video_url} />}
      <div className={styles.kayoralpha}>
        <div className={styles.title}>
          <div className={styles.black}>
            <p>charity release</p>
          </div>
          <h1>{artist_name}</h1>
          <p className={styles.drop}>‘{song_name}’</p>
        </div>
        <div className={styles.content}>
          <div className={styles.left}>
            <p>
            Participate in the charity release of <span className={styles.bold}>‘{song_name}’</span> to support the victims of the earthquake in Turkey and Syria.
            </p>
            <p>All the money you spend will go towards the cause. You’ll own a bit of the song and will earn royalties whenever it’s played on streaming services.</p>
            <p>
              <span>How?</span> Join the waitlist below to receive your invite to buy a bit of the song and receive a
              share of the song’s streaming royalties for life plus exclusive VIP benefits!
            </p>
            <p>
              Join the waitlist now...
            </p>
            <button
              className={"btn btn-yellow"}
              onClick={() => navigate(routes.joinWaitList)}>
              join the <span>waitlist</span>
            </button>
            {perks ? <>
              <p>
              <span>Who said VIP?</span> All purchases come with a package of VIP benefits including
            </p>
            <Benefits benefits={benefits} />
            </> : <></>}
            
          </div>
          <div className={styles.right}>
            {video_url ? <><div className={styles.play} onClick={() => setShowVideo(true)}>
              <img src={playBtn} alt="" />
            </div></> : <></>}
            {str_date ? <>
            <p className={styles.yellow}>COUNTDOWN TO SALE </p>
            <div className={styles.timer}>
              <Countdown time={release_date_epoch} />
            </div>
            </> : <></>}
          </div>
        </div>
      </div>
    </Layout>
  )
}